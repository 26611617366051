import { graphql, PageProps } from "gatsby"
import React from "react"
import "twin.macro"

import { Article, BackgroundImage, Container, Headings, Section } from "@/atoms"
import {
  BreadCrumbs,
  CategoryLinks, Layout, NextSEO, Pagination,
  PostListing,
  SEO
} from "@/components"
import { ListingTemplateQuery, SitePageContext } from "@/gatsby-graphql"
import tw from "twin.macro"

export type ListingTemplateProps = PageProps<ListingTemplateQuery>

function Listing({ pageContext, data, location }: ListingTemplateProps) {
  const postEdges = data.allWpPost.edges
  const { currentPageNum, pageCount } = pageContext

  return (
    <Layout>
      {/* <SEO
        title={
          currentPageNum === 1
            ? "ブログ記事一覧"
            : `ブログ記事一覧-${currentPageNum}ページ目`
        }
        description={
          currentPageNum === 1
            ? "ブログ記事一覧"
            : `ブログ記事一覧-${currentPageNum}ページ目`
        }
      /> */}
      <NextSEO
        type="Article"
        title={currentPageNum === 1 ? "ブログ記事一覧" : `ブログ記事一覧-${currentPageNum}ページ目`}
        description={currentPageNum === 1 ? "ブログ記事一覧" : `ブログ記事一覧-${currentPageNum}ページ目`}
        url={`/blog`}
        breadCrumbs={[
          { title: "ブログ" },
          { title: currentPageNum === 1 ? "ブログ記事一覧" : `ブログ記事一覧 - ${currentPageNum}ページ目`, },
        ]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>ブログ</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs
          breadCrumbs={[
            { title: "ブログ" },
            {
              title:
                currentPageNum === 1
                  ? "ブログ記事一覧"
                  : `ブログ記事一覧 - ${currentPageNum}ページ目`,
            },
          ]}
        />
        <Section>
          <PostListing
            postEdges={postEdges}
            css={{
              ...tw`border p-4 rounded`,
              "&:hover": {
                backgroundColor: '$background-secondary'
              }
            }} />
          {pageCount > 1 && (
            <Pagination pageContext={pageContext} slug={`blog`} />
          )}
        </Section>
      </Article>
    </Layout>
  )
}

export default Listing

export const listingQuery = graphql`
  query ListingTemplate($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date }, skip: $skip, limit: $limit) {
      edges {
        node {
          date
          modified
          databaseId
          excerpt
          uri
          slug
          title
          content
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              databaseId
              name
              slug
              uri
            }
          }
          # tags {
          #   nodes {
          #     name
          #     link
          #   }
          # }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
  }
`
